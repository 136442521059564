#chips {
  @extend #input;

  .mat-mdc-form-field-infix {
    @apply max-h-full;

    .mdc-evolution-chip-set__chips {
      @apply flex-nowrap overflow-auto;

      .mat-mdc-chip {
        @apply h-auto rounded bg-black-50 border border-black-500;
      }
    }
  }
}
