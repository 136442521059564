#answerOption {
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover,
  .mat-expansion-panel-header.mat-focus-indicator.mat-expansion-toggle-indicator-after:focus,
  .mat-expansion-panel-header.mat-focus-indicator.mat-expansion-toggle-indicator-after:hover,
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
    @apply bg-gray-100 #{!important};
  }
  .mat-expansion-panel-content {
    @apply border-b-[1px] border-x-[1px] rounded-b-lg #{!important};
  }
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  @apply shadow-none #{!important};
}
