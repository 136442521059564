@mixin Phone-Width($Width: 300) {
  width: $Width + px;
}

#phone-input .iti {
  display: block !important;

  & input {
    @include Phone-Width;
  }

  & .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: -1px;
    @include Phone-Width(292);
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px !important;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      width: 10px !important;
      height: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & .search-container input {
    font-size: 14px;
    @apply border-black-300;
    border-radius: 0;
    @include Phone-Width(290);
    margin-bottom: 8px !important;

    &:focus {
      outline: none;
    }
  }
}

.iti__flag-container {
  background: #f5f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  &.open + input {
    border-radius: 8px;
  }
}

.iti__selected-flag {
  @apply rounded-lg rounded-r-none  bg-black-100  w-[105px] #{!important};
}

.iti__arrow {
  all: unset;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 14px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

ngx-intl-tel-input {
  & input {
    height: 44px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    font-size: 18px;
    @apply border-black-300;
  }
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  @apply border-error-200 border border-solid;
}

ngx-intl-tel-input input {
  padding-left: 7.5rem !important;
}

ngx-intl-tel-input input#country-search-box {
  padding-left: 0.5rem !important;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  @apply border-black-300;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

.move-dropdown-to-top {
  #phone-input .iti {
    & .dropdown-menu.country-dropdown {
      @include Phone-Width(295);
      @apply border-black-300 mt-0.5 translate-y-[-19rem] #{!important};
    }
  }
}

.move-dropdown-to-bottom {
  #phone-input .iti {
    & .dropdown-menu.country-dropdown {
      @include Phone-Width(295);
      @apply border-black-300 mt-0.5 translate-y-0 #{!important};
    }
  }
}
