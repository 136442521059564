table#small-list-table {
  @apply bg-white border-separate border-spacing-y-1 relative;
  &.reversed {
    @apply bg-black-50 #{!important};
    tbody {
      tr {
        td {
          @apply bg-white #{!important};
        }
      }
    }
  }

  thead {
    tr {
      @apply h-6;
      th {
        @apply border-none font-cerebri;
      }
    }
  }

  tbody {
    tr {
      @apply h-10;
      td {
        @apply text-sm font-light border-none bg-black-50 #{!important};

        &:first-child {
          @apply rounded-s-lg;
          &.grey-background {
            @apply bg-black-100 border-s-black-300 #{!important};
          }
        }

        &:last-child {
          @apply rounded-e-lg;
          &.grey-background {
            @apply bg-black-100 border-s-black-300 #{!important};
          }
        }

        .text-with-icon {
          @apply flex items-center gap-2 relative;

          .menu-content {
            @apply absolute hidden;
          }

          &:hover {
            .menu-content {
              @apply block;
            }
          }
        }
      }
    }
  }
}
