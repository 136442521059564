.mat-mdc-menu-panel {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;

  &.menu--large {
    @apply p-0 max-w-none min-w-[auto] border-black-50 #{!important};
    box-shadow: 2px 5px 10px 0 #0000001a !important;
  }

  @apply border border-black-300 rounded-md p-0 #{!important};

  .mat-mdc-menu-content {
    @apply p-0 #{!important};

    .mat-mdc-menu-item {
      @apply ps-4 pe-20 py-2 min-h-[auto] #{!important};

      .mat-mdc-menu-item-text {
        @apply font-light text-[0.875rem] #{!important};
      }

      &:not(:last-child) {
        .mat-mdc-menu-ripple {
          @apply border-b border-black-100;
        }
      }
    }
  }
}
