﻿#button-list {
  @apply rounded-lg items-center;
}

#button-list-button-button,
#button-list-button {
  @apply w-full h-full;
}

.mat-button-toggle-checked .mat-button-toggle-button {
  @apply bg-primary text-white font-medium;
}

.mat-button-toggle-appearance-standard,
.mat-button-toggle-label-content {
  @apply text-xs p-0 #{!important};
}
