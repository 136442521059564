@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Light-Italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Regular-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Medium-Italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-SemiBold.otf") format("opentype");

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-SemiBold-Italic.otf") format("opentype");

  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-Bold-Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Cerebri-Sans-ExtraBold-Italic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Hanken-Design-Co-Cerebri-Sans-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cerebri Sans";
  src: url("../../fonts/cerebri-sans/Hanken-DesignCo-Cerebri-Sans-Heavy-Italic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
