#postfix-text-input {
  @extend #input;

  .mdc-text-field {
    .mdc-notched-outline {
      .mdc-notched-outline__trailing {
        @apply rounded-e-none border-e-black-300 #{!important};
      }
    }

    &--disabled {
      .mdc-notched-outline {
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch {
          @apply border-black-300;
        }
      }
    }
  }

  // focus
  .mat-mdc-text-field-wrapper:focus-within:not(.mdc-text-field--invalid) span {
    @apply border-black border-l-black-300;
  }

  // error
  .mdc-text-field--invalid span {
    @apply border-error-200 border-l-black-300;
  }
}

#postfix-input {
  :focus-within #postfix-text,
  :focus-within #postfix-email {
    @apply border-black;
  }
}

#postfix-email {
  @extend #postfix-text-input;

  .mdc-text-field {
    .mdc-notched-outline {
      .mdc-notched-outline__trailing {
        @apply rounded-e-none border-e-transparent #{!important};
      }
    }
  }
}
