.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .mat-mdc-list-base {
    @apply py-6;
    .mdc-list-item {
      @apply ps-8 pr-0;
    }
  }
  span {
    @apply text-sm flex items-center font-medium;
  }
  .mdc-list-item__content {
    overflow: unset;
  }
  .mdc-list-item__primary-text {
    overflow: unset;
  }
  .mat-badge-large .mat-badge-content {
    @apply block w-5 h-5 text-xs bg-black-100 leading-5;
  }
  .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -10px;
    top: -11px;
  }
}
.mat-sidenav-container {
  .mat-sidenav {
    @apply w-60 bg-black-50;
  }
}
.mat-sidenav-content {
  & .mat-toolbar-single-row {
    @apply h-28 border-b bg-transparent justify-between flex-wrap;
  }
}
.mat-drawer-inner-container .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  @apply bg-primary text-white;
}

.mdc-switch:enabled {
  .mdc-switch__track {
    &::after {
      @apply bg-primary-60 #{!important};
    }
  }
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  @apply bg-primary #{!important};
}
.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after {
  @apply bg-primary-60 #{!important};
}
.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  @apply bg-primary-60 #{!important};
}
