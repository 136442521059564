image-cropper {
  width: 242px !important;
  height: 228px !important;

  .ngx-ic-cropper.ngx-ic-round.ng-star-inserted {
    outline-color: rgba(180, 175, 175, 0.5) !important;
    color: transparent !important;
    border-radius: 100% !important;
  }

  .ngx-ic-source-image {
    @apply w-full h-full object-cover;
  }
}

.cropped-img {
  @apply sm:w-44 sm:h-44  lg:w-56  lg:h-56 overflow-hidden rounded-full object-cover;
}

.mat-mdc-slider {
  .mdc-slider__track {
    .mdc-slider__track--active {
      .mdc-slider__track--active_fill {
        @apply border-gray-400 #{!important};
      }

      @apply bg-gray-400 h-1 #{!important};
    }

    .mdc-slider__track--inactive {
      @apply bg-transparent  #{!important};
    }
  }

  .mdc-slider__thumb-knob {
    @apply bg-primary border-primary border-0 w-4 h-4 shadow-none #{!important};
  }

  @apply w-60 #{!important};
}
