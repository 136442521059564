table#large-list-table {
  @apply bg-white border-separate border-spacing-y-1 relative;
  thead {
    tr {
      @apply h-6;
      th {
        font-family: "Cerebri Sans", "Open Sans", Arial, sans-serif !important;
        @apply border-none font-cerebri;
      }
    }
  }

  tbody {
    tr {
      td {
        font-family: "Cerebri Sans", "Open Sans", Arial, sans-serif !important;
        @apply border-y border-black-300 bg-black-50 border-solid #{!important};

        &:first-child {
          @apply border-s;
          @apply border-e;

          &.rounded-grey-border {
            @apply border-s-8 border-s-black-500 #{!important};
          }

          &.rounded-green-border {
            @apply border-s-8 border-s-status-available #{!important};
          }
        }

        &:last-child {
          &.grey-background {
            @apply bg-black-100 border-s-black-300 #{!important};
          }

          @apply border-e border-s;
        }
      }
    }
  }
}
