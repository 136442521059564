#text-Accordion {
  .mat-accordion {
    .mat-expansion-panel {
      @apply rounded-tl-lg rounded-tr-lg shadow-none border border-black-300 #{!important};

      .mat-expansion-panel-header {
        @apply bg-black-50 border-b-black-300 h-[4rem] #{!important};
      }
    }
  }
}
