#main-box {
  grid-template-columns: repeat(3, 24rem);
  row-gap: 0.938rem;

  @media only screen and (min-width: 2000px) {
    grid-template-columns: repeat(4, 24rem);
  }

  @media only screen and (max-width: 1580px) {
    grid-template-columns: repeat(2, 24rem);
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 24rem);
  }
}
