.mdc-linear-progress {
  @apply bottom-[0.156rem];
}
.mat-horizontal-stepper-header-container {
  @apply hidden #{!important};
}
.mat-step-header {
  @apply hidden #{!important};
}

.mdc-linear-progress__buffer-bar {
  @apply bg-transparent #{!important};
}
.mdc-linear-progress__bar-inner {
  @apply border-primary #{!important};
}

#matStepperNext {
  @apply bg-primary;
}
.mat-stepper-horizontal {
  .mat-horizontal-content-container {
    @apply p-0;
  }
}
