#import-table {
  @apply border-separate border-spacing-x-[1px] border-spacing-y-1;
  thead {
    tr {
      @apply h-10;
      th {
        font-family: "Cerebri Sans", "Open Sans", Arial, sans-serif !important;
        @apply bg-black-100 whitespace-nowrap font-medium text-xs py-0 ps-4 border-b-0;
        &:first-child {
          @apply rounded-s-lg ps-1;
        }

        &:last-child {
          @apply rounded-e-lg;
        }
      }
    }
  }

  tbody {
    tr {
      @apply h-10;
      td {
        font-family: "Cerebri Sans", "Open Sans", Arial, sans-serif !important;
        @apply ps-4 bg-black-100 border-b-0 text-sm font-light whitespace-nowrap;

        &:first-child {
          @apply ps-1 rounded-s-lg;
        }

        &:last-child {
          @apply rounded-e-lg;
        }
      }
    }
  }
}
