﻿.mdc-tab--active {
  .mdc-tab__content {
    @apply bg-secondary border-secondary;
  }

  .mdc-tab__text-label {
    @apply text-white font-medium #{!important};
  }
}

.mat-mdc-tab-labels {
  @apply flex overflow-x-auto gap-4 my-6 mx-6 py-1;
}

.mdc-tab__text-label {
  @apply text-black transition-none #{!important};
}

.mdc-tab {
  @apply p-0 #{!important};
}

.mdc-tab__content {
  @apply capitalize font-light grid place-items-center w-full px-4 min-w-fit py-2.5 text-black bg-white border border-black-300 rounded-lg cursor-pointer;
}

.mdc-tab-indicator__content {
  @apply hidden;
}

.mat-mdc-tab-list {
  @apply border-b-black-100 border-b sticky top-0 z-10 #{!important};
}
