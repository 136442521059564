@import "ckeditor5/ckeditor5.css";

:root {
  --ck-color-button-on-color: rgb(var(--clr-primary));
  --ck-color-focus-border: rgb(var(--clr-primary));
  --ck-color-focus-outer-shadow: rgba(var(--clr-primary) / 30%);
}

.main-container {
  @apply rounded-lg mt-4 w-full xl:w-4/5;

  .ck-content > * {
    all: revert;
  }

  .ck-content {
    @apply min-h-40 text-black #{!important};
  }

  .ck.ck-placeholder::before,
  .ck .ck-placeholder::before {
    left: 40px !important;
  }

  .ck.ck-dropdown__panel {
    @apply max-h-40   overflow-y-auto;
    .ck.ck-list__item > .ck-button.ck-on {
      color: black !important;
    }
  }

  .editor-container {
    @apply rounded-lg border-black-300 border;
  }

  .editor-container__toolbar {
    @apply rounded-lg;
    .ck-toolbar {
      @apply bg-black-50 border-none min-h-20 flex-wrap px-4 py-2;

      .ck.ck-toolbar__separator {
        @apply me-2 invisible;
      }

      .ck-toolbar__items {
        @apply flex-wrap #{!important};
        .ck-button:not(.ck-insert-table-dropdown-grid-box) {
          @apply bg-white border border-black-200 rounded-sm p-2 m-0;
          &:active {
            @apply z-10;
          }
        }
      }
    }
  }
}
