#textarea {
  @extend #input;
  @apply h-full w-full #{!important};

  .mat-mdc-form-field-flex {
    @apply items-start py-2.5 #{!important};

    .mat-mdc-form-field-infix {
      @apply h-full #{!important};

      textarea {
        @apply h-full #{!important};
      }
    }
  }
}
