#timepicker {
  @extend #input;

  &.error {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch {
          @apply border-error-200 #{!important};

          label {
            @apply text-error-200;
          }
        }
      }
    }
  }
}
