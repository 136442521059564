@import "input";

#datepicker {
  @apply h-[2.75rem];
  &.invalid {
    .mdc-text-field {
      @apply border-error-200;
    }

    .mdc-floating-label {
      @apply text-error-200;
    }
  }

  .mdc-text-field {
    @apply overflow-visible h-full border border-black-300 rounded-lg ps-3 focus:border-black;
    @apply items-center #{!important};

    .mat-mdc-form-field-flex {
      @apply h-full items-center #{!important};
    }

    &--focused {
      @apply border-black;
    }

    &:hover {
      @apply shadow-[0px_1px_1px_0px_rgba(0,0,0,0.12)];
    }
  }

  .mdc-icon-button {
    @apply h-8 w-8 me-2 p-0 text-center #{!important};

    img {
      @apply m-auto w-3.5 h-3.5 max-w-none #{!important};
    }

    &.mat-mdc-button-disabled {
      @apply opacity-40;
    }
  }

  .mat-mdc-form-field-flex {
    @apply h-[2.75rem];
    .mat-mdc-form-field-infix {
      @apply py-2.5 min-h-[auto];
    }
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    @apply top-[22px] text-sm;
  }

  .mdc-line-ripple,
  .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-focus-overlay {
    @apply h-full bg-white hover:bg-white #{!important};
  }

  .mdc-floating-label {
    @apply bg-white px-1 text-black font-light;

    &--float-above {
      @apply text-black-500 font-medium text-xs #{!important};
      transform: translateY(calc(calc(0.5px + var(--mat-form-field-container-height) / 2) * -1))
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }
  }
}

.mat-datepicker-popup {
  .mat-datepicker-content {
    .mat-calendar-body-selected {
      @apply bg-primary #{!important};
    }

    .mat-calendar-body-cell {
      &:hover {
        .mat-calendar-body-cell-content {
          @apply bg-primary-60  #{!important};
        }
      }
    }
  }
}
