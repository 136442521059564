.mat-mdc-slide-toggle {
  .mdc-switch {
    @apply me-4 translate-y-[1px] #{!important};
    &.mdc-switch--selected .mdc-switch__icon,
    &.mdc-switch--unselected .mdc-switch__icon {
      @apply hidden #{!important};
    }

    &--disabled {
      @apply opacity-60 #{!important};
      .mdc-switch__track {
        @apply opacity-30 #{!important};
        &::after {
          @apply bg-primary #{!important};
        }
      }
    }

    &--disabled.mdc-switch--selected {
      .mdc-switch__handle-track {
        .mdc-switch__handle {
          &::before,
          &::after {
            @apply bg-primary #{!important};
          }
        }
      }
    }
  }
}
