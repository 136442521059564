#event-card {
  .mat-expansion-panel {
    @apply relative  shadow-none rounded-lg #{!important};
    .mat-expansion-panel-header {
      .mat-expansion-indicator {
        @apply absolute bottom-4 right-6 z-10;
      }
    }
    .mat-expansion-panel-body {
      @apply hidden  #{!important};
    }
  }
}

.mat-focus-indicator {
  @apply static;
}
.mat-expansion-indicator::after {
  @apply border-black;
}
.mat-content {
  @apply block #{!important};
}
