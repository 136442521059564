.mdc-radio__outer-circle {
  @apply border-[1.5px] border-black-300 #{!important};
}

.mdc-radio__background {
  @apply bg-white rounded-full;
  & .mdc-radio__inner-circle {
    @apply border-primary bg-primary #{!important};
  }
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  @apply hidden #{!important};
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  @apply bg-primary #{!important};
}

#change-time-slot {
  .mdc-form-field.mat-internal-form-field {
    @apply w-full #{!important};
    .mdc-label {
      @apply w-full h-full py-3 #{!important};
    }
  }
  .mat-mdc-radio-checked {
    @apply bg-black-100 border-black #{!important};
  }
}
