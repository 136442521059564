﻿#end-user-visibility-input > mat-radio-button > div > label.mdc-label {
  @apply ps-4 #{!important};
}

#end-user-visibility-input > mat-radio-button > div > div.mdc-radio {
  @apply p-0 #{!important};
}

#end-user-visibility-input > mat-radio-button > div > div.mdc-radio > input {
  @apply w-5 h-5 #{!important};
}
