.mdc-snackbar {
  @apply m-0 #{!important};

  &__surface {
    @apply bg-white rounded-t-none rounded-b-lg p-0 #{!important};
    box-shadow: 0 4px 4px 0 #00000040 #{!important};

    .mat-mdc-snack-bar-label {
      @apply p-0 text-black #{!important};
    }
  }
}
