.mat-mdc-select {
  .mat-pseudo-checkbox {
    @apply hidden;
  }
}
.mat-mdc-option {
  .mat-pseudo-checkbox-minimal {
    @apply hidden;
  }
}
.mat-mdc-select-min-line {
  @apply mr-2;
}
.activePage {
  @apply bg-black-200 border border-black-300 font-semibold;
}
